@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body {
  font-family: 'Lato', serif;
  overflow: hidden;
}

.home {
  overflow: auto;
}

.hidden-square {
  opacity: 0;
  transform: scale(0);
}

.square {
  opacity: 1;
  transform: scale(1);
  animation: appear 0.3s ease-in-out forwards;
}

@keyframes appear {
  0% {
    transform: scale(0);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

